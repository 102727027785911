.color-alert {
    color: $util-red;
}

.color-invert {
    color: $white;
}

.hide {
    display: none;
}