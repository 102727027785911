table {
  width: 100%;
  table-layout: fixed;
}

th {
  font-weight: $font-weight-bold;
  text-align: left;
}

th, td {
  padding: $global-spacing-unit-tiny;
}