.breadcrumbs {
    font-size: $font-size-large;
    padding:$global-spacing-unit-small 0;
    border-top:1px solid $gray-1;
    
    [aria-hidden="true"] {
        &:after {
            content: "/";
            display: inline-block;
            color: $black;
            margin: 0 .5rem;
        }
    }

    &__link{
    	color:$black;
    }
}