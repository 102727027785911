.stdcls
{
    FONT-SIZE: 12px;
    FONT-FAMILY: 'Roboto', sans-serif;
}
.msg_txt
{
    FONT-SIZE: 12px;
    font-style: italic;
    font-weight: bold;
    FONT-FAMILY: 'Roboto', sans-serif;
}
.table_red
{
    FONT-SIZE: 11px;
    color: Red;
    FONT-FAMILY: 'Roboto', sans-serif;
}
.linkline
{
    FONT-SIZE: 14px;
    FONT-FAMILY: 'Roboto', sans-serif;
}
.hli
{
    BACKGROUND-COLOR: #ffffbb
}
.buttons
{
    BACKGROUND-COLOR: #d4d0c8;
    font-size: 11px;
    font-family: 'Roboto', sans-serif;
}
#divdeptsettings
{
    background-color:#ffffff;
    border: 1px solid #e4e4e4;
}
#divbanner
{
    background-color:#ffffff;
    border: 1px solid #e4e4e4;
    width:780px;
}
.admin-panel 
{
    background-color:#ffffff;
    border: 1px solid #e4e4e4;
}
.instr
{
    FONT: 10pt Arial, Helvetica, sans-serif;
    COLOR: #000000
}
.text
{
    FONT-SIZE: 11px;
    LINE-HEIGHT: 18px;
    FONT-FAMILY: 'Roboto', sans-serif;
}
.err_txt
{
    FONT-SIZE: 11px;
    LINE-HEIGHT: 18px;
    color: Red;
    FONT-FAMILY: 'Roboto', sans-serif;
}

.white
{
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: white;
}

.white a:hover
{
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: white;
    text-decoration: none;
}

.white a:visited
{
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: White;
    text-decoration: underline;
}

.gridView, .gridView a
{
    color:Gray;
    font-family: 'Roboto', sans-serif;
    /*font-size:11px;*/
    text-align:center;
}

.gridView td
{
    padding:3px;
    /*min-height:20px;*/
}
.gridView th
{
    padding:3px;
}

.gridView table
{
    width:770px;
}
.selectedRow
{
    background-color:#EEEEEE;
}
/* Accordion */
.accordionHeader
{
    border: 1px solid #2F4F4F;
    color: white;
    background-color: #35628c;
	font-family: 'Roboto', sans-serif;
	font-size: 10px;
	font-weight: bold;
    padding: 5px;
    margin-top: 1px;
    cursor: pointer;
}
.accordionHeader a:link
{
	color: #FFFFFF;
	background: none;
	text-decoration: none;
}
.accordionHeader a:hover
{
	background: none;
	text-decoration: none;
}
.accordionHeader a:visited
{
	background: none;
	text-decoration: none;
}
.accordionHeaderSelected
{
    border: 1px solid #2F4F4F;
    color: white;
    background-color: #4579aa;
	font-family: 'Roboto', sans-serif;
	font-size: 10px;
	font-weight: bold;
    padding: 5px;
    margin-top: 2px;
    cursor: pointer;
	text-decoration: none;
}
.accordionHeaderSelected a:link
{
	color: #FFFFFF;
	background: none;
	text-decoration: none;
}
.accordionHeaderSelected a:hover
{
	background: none;
	text-decoration: none;
}
.accordionHeaderSelected a:visited
{
	background: none;
	text-decoration: none;
}
.accordionContent
{
    background-color: #f3f3f3;
    border: 1px dashed #2F4F4F;
    border-top: none;
    padding: 5px;
    padding-top: 10px;
}

.ccbody
{
    FONT-SIZE: 11px;
    FONT-FAMILY: 'Roboto', sans-serif;
}
.ccbody_red
{
    FONT-SIZE: 11px;
    color: Red;
    FONT-FAMILY: 'Roboto', sans-serif;
}

.black_overlay
{  
    display: block;  
    width: 100%;  
    height: 100%;  
    background-color: black;  
    z-index:1001;  
    -moz-opacity: 0.02;  
    opacity:.02;  
    filter: alpha(opacity=2);  
}  

.white_content 
{  
    display: block;  
    border:solid 3px #ccc;      
    background-color: white;  
    z-index:1002;  
    overflow: auto;
    /*padding-left:5px;*/
    /*margin-top:-370px; */
} 

.black_overlay2
{  
    display: block;  
    width: 100%;  
    height: 100%;  
    background-color: black;  
    z-index:1001;  
    filter:alpha(opacity=70);
    opacity:0.7px;
}  


#Top_Navigation 
{
    width:780px;
	height:27px;
	float:left;
}

#Top_Navigation ul{
	height:25px;
	background-image:url(../admin/AImages/topnav_bg.jpg);
	background-repeat:repeat-x;
	color:#3E3E3E;
	font-family: 'Roboto', sans-serif;
	font-size:12px;
	font-weight:bold;
	/*border-left:1px solid #000000;*/
	margin:0px 0px 0px 0px;
}

#Top_Navigation ul li{
	display: inline;
	list-style-type: none;
}

#Top_Navigation ul li a{
	height: 20px;
	color: #3E3E3E;
	text-align: center;
	text-decoration: none;
	padding:5px 45px 0px 45px;
	margin:0px 0px 0px 0px;
	float:left;
	display:block;
	border-right: 1px solid #FFFFFF;
}

#Top_Navigation ul li a:hover{
	color: #3E3E3E;
	text-decoration:none;
	color:White;
	background-image:url(../admin/AImages/topnav_bg_ro.jpg);
	overflow: auto;
}

.draft {
    background-color: lightYellow;
    border: 2px solid #E6DB55;
    width: 99%;
    padding: 5px;
    font-weight: bold;
}
/*.admin_banner {
	width:1025px;
	height:225px;
	background:url('/admin/AImages/banner_bg.jpg');
}*/

.admin_banner {
    
    height: 59px;
    /*background: url('/admin/AImages/avidcmslogo.png');*/
    background-repeat: no-repeat;
    margin: 33px 0 5px 0px;
    /*border-bottom: 38px solid white;*/
    padding: 0 0 25px 0;
}

.admin-logo
{
    width: 237px;
    float: left;
    padding: 0 0 0 32px;
}

.dept_title {
	width:271px;
	height:41px;
	color:#FFFFFF;
	font-family: 'Roboto', sans-serif;
	font-size:24px;
	font-weight:bold;
	padding: 152px 0 0 15px;
}
.admin-button {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    border:none;
    color:#FFFFFF;
    background-color:#E76C3D;
    padding:3px 8px;
    font-family: 'Roboto', sans-serif;
    font-size:14px;
    cursor:pointer;
}
.admin-button:hover {
    text-decoration:underline;
}

/*---------------------*/
/*---CMS 3 DashBoard---*/
/*---------------------*/

img 
{
    border:none;
}

/*.cms-header-links
{
    padding: 88px 0 0 850px;
}*/

.cms-header-links
{
    padding: 0 0 0 680px;   
}

.cms-visit-site
{
    float: left;
    padding: 0 3px 0 0;
}

.cms-change-password
{
    float: left;
    padding: 0 3px 0 0;
}

.cms-logout
{
}

.float-clear 
{
    clear:both;
    height:0;
    width:100%;
}


.cms-wrapper
{
    font-family: 'Roboto', sans-serif;
}

.cms-column-one
{    
    width: 230px;
    float: left;
}

.admin-menu-row
{
    position:relative;
    width:107px;
    height:107px;
    padding: 0 0 10px 90px;
}

.admin-menu-row img
{
    border: none;
}
 
.admin-flyout-wrapper 
{
    width:790px;
    position:absolute;
    left:197px;
    top:-5000px;
}

.admin-menu-row:hover .admin-flyout-wrapper
{
    top:0px;
}

.admin-link-wrapper 
{
    display:block;
    margin:0;
    padding:0 0 15px 0;
    background-color:#ACCAE6;
    float:left;
}

.fly-out-left
{
    float:left;
    margin:0;
   
}

.fly-out-right
{
    float:left;
    margin:0;
   
}

/*
.admin-flyout-wrapper 
{
    width:600px;
    position:absolute;
    left:197px;
    top:-1000px;
}
*/

.admin-flyout-link 
{
    display:block;
    width:180px;
    height:0;
    padding:12px 10px 34px 0px;   
    list-style:none;
    float:left;    
}

.admin-flyout-link a 
{
    display:block;
    width:189px;
    height:15px;
    padding:13px 16px;
    margin:0;
    font:normal 12px Arial;
    text-decoration:none;
    color:#000;    
    background-image: url('AImages/dashboard/flyout-btn.png');
    background-repeat:no-repeat;
    color: #4D5D6A;
    
}

.admin-flyout-link a:hover 
{
    background-image: url('AImages/dashboard/flyout-btn-RO.png');
    color:#fff;
}


.admin-flyout-1column 
{
    width:181px;
}

.admin-flyout-2column 
{
    width:380px;
}

.admin-flyout-3column 
{
    width:570px;
}

.admin-flyout-4column 
{
    width:760px;    
}


.cms-column-two
{
    border-left: 2px solid #EDECEA;
    float: left;
    width: 760px;
}

.cms-column-two-top
{
   
    float: left;    
    padding: 0 0 15px 30px;  
}

.column-two-left-side
{
    float: left;
    background-color: white;
    width: 300px;
    margin: 0 0 0 25px;
    padding: 15px;
    border-radius: 15px;
}

.user-row
{
    padding: 5px;
    font-size: 15px;
}

.user-title
{
    font-weight: bold;
     border-bottom: 1px solid #B2B2B2;
     padding: 0 0 5px 0;
     font-size: 20px;
}

.user-info
{
    float: left;
    width: 270px;
}

.labels
{
}

.column-two-right-side
{
    background-color: white;
    width: 300px;
    float: left;
    margin: 0 0 0 20px;
    padding: 15px;
    border-radius: 15px;    
}

.cms-column-two-bottom
{
    /*border-top: 2px solid #EDECEA;*/
    float: left;
    margin: 0 0 0 25px;
    width: 740px;    
    float: left;
    margin: 0 0 0 25px;
    width: 740px;    
}

.etools-logo
{ 
    padding: 0px 0 15px 30px;
}

.etools-row-wrapper
{
    padding: 20px 0 20px 35px;
}

.etools-rows
{
    padding:10px 0;
    margin: 0;
    display: inline-block;
}


.etool-icon
{
    width: 212px;
    float: left;
}

.etool-button-wrapper
{
   float: left;
}


.etools-buttons
{
    display:block;
    float: left;
    padding: 3px 0 2px 15px;
    margin: 5px;
    background: $white;
    border-radius: $global-border-radius;
    width: 180px;
    height: 30px;
    background-repeat: no-repeat;
    text-decoration:none;
    line-height: 26px;
    font-size: 12px;
}

.etools-buttons:hover
{    
    background-image: url('AImages/dashboard/etools-btn-RO.png');
    color:#fff;  
}

/* Style added 12-13-2012 */
.RowStyle
{
    border-width:1px;
    border-color:#C0C0C0;
    border-style:solid;
    padding:3px;
    background-color:White;
    height:30px;
}
.SelectedRowStyle
{
    border-width:1px;
    border-color:#C0C0C0;
    border-style:solid;
    padding:3px;
    /*background-color:Yellow;*/
    background-color:#e76c3d;
    color:White;
    font-weight:bold;
}
.SelectedRowStyle a
{
    color:White;
}    
.PagerStyle table    
{
    text-align:center;
    margin:auto;
}
.PagerStyle table td
{
    border:0px;
    padding:5px;
}
.PagerStyle td
{
    border-top: #707070 1px solid;
}
.PagerStyle a
{
    font-weight:bold;
    text-decoration:none;
    padding:2px 5px 2px 5px;
    background-color:#e76c3d;
    color:#FFFFFF;            
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
    filter:alpha(opacity=70);
    opacity:0.7;	                      
}
.PagerStyle a:hover 
{
    filter:alpha(opacity=100);
    opacity:1.0;	                      
}
        
.PagerStyle span
{
    font-weight:bold;
    text-decoration:none;
    padding:2px 5px 2px 5px;
    background-color:#e76c3d;
    color:#FFFFFF;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;              
}     

.HeaderStyle th 
{
    border-width:1px;
    border-color:#C0C0C0;
    border-style:solid;    
    text-align:left;
}

a.disable, a.disable:hover
{
    background-image: url('/admin/AImages/dashboard/flyout-btn-null.png') !important;
    color: #AAAAAA !important;
    text-shadow: 1px 1px white;   
}

.draft {
    background-color: lightYellow;
    border: 2px solid #E6DB55;
    width: 99%;
    padding: 5px;
    font-weight: bold;
}

/*  Admin Universal Navigation Styles
=========================================*/

#admin-topnav-wrapper {
	position:relative;
	width:960px;
	height:36px;
	margin:20px auto 10px auto;
	background: #606060; /* Old browsers */
	background: -moz-linear-gradient(top, #606060 0%, #525252 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#606060), color-stop(100%,#525252)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #606060 0%,#525252 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #606060 0%,#525252 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #606060 0%,#525252 100%); /* IE10+ */
	background: linear-gradient(to bottom, #606060 0%,#525252 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606060', endColorstr='#525252',GradientType=0 ); /* IE6-9 */
}

#admin-topnav-menu {
	display:block;
	height: 36px;
	list-style: none outside none;
	margin: 0 auto;
	width: auto;
	padding:0;
	float:left;
}

.admin-topnav-menuitem {
	display: block;
	float: left;
	position: relative;
	text-align: center;
	height: 36px;
	margin:0;
	padding:0;
}

.admin-topnav-menuitem:hover 
{
    background: #f2f2f2; /* Old browsers */
	background: -moz-linear-gradient(top,  #f2f2f2 1%, #cccccc 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,#f2f2f2), color-stop(100%,#cccccc)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #f2f2f2 1%,#cccccc 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #f2f2f2 1%,#cccccc 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #f2f2f2 1%,#cccccc 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #f2f2f2 1%,#cccccc 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
}


.admin-topnav-menuitem a {
	display:block;
	height:27px;
	font-family: 'Roboto', sans-serif;
	font-size:12px;
	text-decoration:none;
	padding:9px 15px 0 15px;
	color:#fff;
	border-left:1px solid #525252;
	border-right:1px solid #707070;
	font-weight: normal;
}

.admin-topnav-menuitem a:hover, .admin-topnav-menuitem:hover a {	
	color:#1A1A1A;
}

.admin-topnav-flyouts {
	width:200px;
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background: #F2F2F2;
	border-right:1px solid #606060;
	border-bottom:1px solid #606060;
	border-left:1px solid #606060;
	box-shadow: 1px 3px 4px #ccc;
	float: left;
	top:-1000px;
	margin: 0 auto;
	padding: 10px 0 15px 0;
	position: absolute;
	text-align: left;
	z-index:9999;
}

.admin-topnav-subnav {
	list-style: none outside none;
	margin: 0 auto;
	width: auto;
	padding:0;
	border:none;
}

.admin-topnav-subnav li
{
    background: none !important;
    text-align: left;
    width: 100%;
}
    
.admin-topnav-subnav a {
	color:#1A1A1A;
	border:none;
	height:20px;
}

.admin-topnav-subnav a:hover {
	background:none;
	text-decoration:underline;		
}

.admin-topnav-menuitem:hover .admin-topnav-flyouts {		
	top: auto;
}

#admin-version-number {
	height:27px;
	float:right;
	font-family: 'Roboto Condensed', sans-serif;
	font-size:11px;
	color:#fff;
	text-align:right;
	padding:9px 10px 0 0;
}
.size
{
    width: 250px;
}

#admin-coe {
	height:27px;
	float:left;
	font-family: 'Roboto Condensed', sans-serif;
	font-size:11px;
	color:#fff;
	text-align:right;
	padding:9px 10px 0 0;
	margin-left:30px;
}

#adminss-topnav-wrapper {
	position:relative;
	width:960px;
	height:36px;
	margin:10px auto;
	background: #e76c3d; /* Old browsers */
	background: -moz-linear-gradient(top, #e76c3d 0%, #B64216 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e76c3d), color-stop(100%,#B64216)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #e76c3d 0%,#B64216 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #e76c3d 0%,#B64216 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #e76c3d 0%,#B64216 100%); /* IE10+ */
	background: linear-gradient(to bottom, #e76c3d 0%,#B64216 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e76c3d', endColorstr='#B64216',GradientType=0 ); /* IE6-9 */
}

.etools-logo {
    margin-left:30px;
    margin-bottom:10px;
}

.light,.medium,.heavy{
    display:none;
}

.primary-care-ad .subsite-config-edit {
    display: inline-block;
    position: relative;
    height: 29px;
    margin-top: 35px;
    /* bottom: -33px; */
}

a.subsite-config-edit {
    display: inline-block;
}

.edit-btn-wrapper + h2 {
    display: inline-block;
    color: white;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: bold;
    display: none;
}

.edit-btn-wrapper {
    display: inline-block;
}

.config-header-wrapper {
    /* background-color: #0E6C56; */
    height: 35px;
    border-radius: 6px 6px 0 0;
    /* margin-top: 20px; */
    padding-top: 15px;
}


.subsite-template-bottom-row {}

.main_content.fl-rt.ss3 {}

.subsite-template-top-row {
    display: inline-block;
    width: 100%;
}

.subsite-template-bottom-row .row-two.col-one {
}

.subsite-template-bottom-row .ad img {
    max-width: 100%;
}

.primary-care {}

.subsite-cofiguration.bottow-row.column-two {
    float: left;
    width: 30%;
}

.admin-homepage-bottom.row-two.col-two.heavy {
    /* display: inline-block; */
    float: left;
    width: 23%;
}

.bottow-row .primary-care > h2, .col-two.heavy .primary-care > h2 {width: 95%;background-color: #0A6953;height: 30px;padding-top: 15px;padding-left: 31px;color: white;font-family: sans-serif;font-weight: bold;}

.primary-care .subsite-config-edit {
    position: relative;
    bottom: -33px;
    z-index: 99;
}

.screen-name-box {
    margin: 0 20px;
}

.subsite-cofiguration.bottow-row.column-one {
    float: left;
    width: 65%;
    margin-right: 25px;
}

#divSubsiteAdminNavigation .admin-topnav-menuitem {
    max-width: 145px;
    height: 42px;
}

#divSubsiteAdminNavigation #adminss-topnav-wrapper {
    height: 42px;
}

#divSubsiteAdminNavigation .admin-topnav-menuitem a {
    height: 33px;
}

ul.ulMedGroup2 {
    display: inline-block;
    padding-left: 0;
    margin: 0;
}

.ulMedGroup2 li {
    display: inline-block;
    float: left;
    position: relative;
    line-height: 25px;
}

.table.display-button {
    display: inline-block;
    float: left;
    top: 0;
    position: absolute;
}

.ulMedGroup2 li:first-child {
    width: 150px;
    height: 25px;
}

#divSubsiteAdminNavigation .admin-topnav-menuitem {
    max-width: 145px;
    height: 42px;
}

#divSubsiteAdminNavigation #adminss-topnav-wrapper {
    height: 42px;
}

#divSubsiteAdminNavigation .admin-topnav-menuitem a {
    height: 33px;
}

ul.ulMedGroup2 {
    display: inline-block;
    padding-left: 0;
    margin: 0;
}

.ulMedGroup2 li {
    display: inline-block;
    float: left;
    position: relative;
    line-height: 25px;
}

.table.display-button {
    display: inline-block;
    float: left;
    top: 0;
    position: absolute;
}

.ulMedGroup2 li:first-child {
    width: 150px;
    height: 5px;
}

#divSubsiteAdminNavigation .admin-topnav-menuitem {
    max-width: 145px;
    height: 42px;
}

#divSubsiteAdminNavigation #adminss-topnav-wrapper {
    height: 42px;
}

#divSubsiteAdminNavigation .admin-topnav-menuitem a {
    height: 33px;
}

ul.ulMedGroup2 {
    display: inline-block;
    padding-left: 0;
    margin: 0;
}

.ulMedGroup2 li {
    display: inline-block;
    float: left;
    position: relative;
    line-height: 25px;
}

.table.display-button {
    display: inline-block;
    float: left;
    top: 0;
    position: absolute;
}

.ulMedGroup2 li:first-child {
    width: 150px;
    height: 25px;
}

.ulMedGroup2 li + li {
    /* display: none; */
}
