.accordion {
    
    &__header {
        display: block;
        width: 100%;
        cursor: pointer;
        background: $white;
        padding: $global-spacing-unit-small;
        font-weight: $font-weight-bold;
        @include font-size($font-size-large, 1);
        margin-bottom: $global-spacing-unit-small;
        box-shadow: $global-box-shadow;
        
        &:after {
            float: right;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 300;
            content: fa-content($fa-var-chevron-down);
            @include fa-icon;
        }
        
        &.is-active {
            margin-bottom: 0;
            
            &:after {
                transform: rotate(180deg);
            }
        }
        
        .form__synced-field {
            position: relative;
            right: auto;
            bottom: auto;
            margin-left: $global-spacing-unit-small;
        }
    }
    
    &__content {
        background: $white;
        padding: $global-spacing-unit-small $global-spacing-unit;
        margin-bottom: $global-spacing-unit-small;
        box-shadow: $global-box-shadow;
    }
}