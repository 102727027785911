//VARS 
// Use these vars to style this component. 
// Before editing familiarize with the global settings.
// Be aware that these changes could cause inconsistencies throughtout the design.

$pagination-padding-unit-y: $global-spacing-unit-tiny;
$pagination-padding-unit-x: $global-spacing-unit-small;
$pagination-radius: 0;

.pagination {
    display: flex;
    justify-content: center;
    border-radius: $pagination-radius;
    @include font-size($font-size-base);
    
    &__item {
        a, span {
            display: block;
            color: $gray-6;
            background: $white;
            border-top: 1px solid $gray-4;
            border-bottom: 1px solid $gray-4;
            border-right: 1px solid $gray-4;
            padding: $pagination-padding-unit-y $pagination-padding-unit-x;
        }
        
        a {
            &:hover {
                transition: $global-transition-time;
                color: $white;
                background-color: $theme-primary-1;
            }
        }
        
        span {
            color: $white;
            background-color: $theme-primary-1;
            font-weight: $font-weight-bold;
        }
        
        &:first-child {
            a, span {
                border-left: 1px solid $gray-4;
            }
        }
    }

    
    > .active {
        > a,
        > a:hover {
            background-color: $theme-primary-2;
            border-color: $theme-primary-2;
            font-weight: $font-weight-bold;
        }
    }
}

// Styles pagination from classes and events
#pagination .pagination {
    width: 100%;
    margin-top: 10px;
    text-indent: inherit;
    padding-left: 0;
    
    > li {
        &:before {
            display: none;
        }
        
        .pagination-link, a, span {
            display: block;
            color: $gray-6;
            background: $white;
            border-top: 1px solid $gray-4;
            border-bottom: 1px solid $gray-4;
            border-right: 1px solid $gray-4;
            padding: $pagination-padding-unit-y $pagination-padding-unit-x;
            
            &:hover {
                transition: $global-transition-time;
                color: $white;
                background-color: $theme-primary-1;
            }
        }
        
        &:first-child {
            a, span {
                border-left: 1px solid $gray-4;
            }
        }
        
        .pagination-link.current-page {
            color: $white;
            background-color: $theme-primary-1;
            font-weight: $font-weight-bold;
        }
    }
}

// Styles pagination as a table
.pagination-table table {
    display: flex;
    justify-content: center;
    border-radius: $pagination-radius;
    @include font-size($font-size-base);
    
   td {
        a, span {
            display: block;
            color: $gray-6;
            background: $white;
            border-top: 1px solid $gray-4;
            border-bottom: 1px solid $gray-4;
            border-right: 1px solid $gray-4;
            padding: $pagination-padding-unit-y $pagination-padding-unit-x;
        }
        
        a {
            &:hover {
                transition: $global-transition-time;
                color: $white;
                background-color: $theme-primary-1;
            }
        }
       
       span {
            color: $white;
            background-color: $theme-primary-1;
           font-weight: $font-weight-bold;
       }
        
        &:first-child {
            a, span {
                border-left: 1px solid $gray-4;
            }
        }
    }

    
    > .active {
        > a,
        > a:hover {
            background-color: $theme-primary-2;
            border-color: $theme-primary-2;
            font-weight: $font-weight-bold;
        }
    }
}