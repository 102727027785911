/*
* Misc repeating design patterns
*/
$global-border-radius: 5px !default;
$global-transition-time: .5s !default;

/*
* Wrappers
*/
$global-wrapper-spacing-sm: $global-spacing-unit-small !default;
$global-wrapper-spacing-lg: $global-spacing-unit !default;
$global-wrapper-width: 95% !default;
$global-box-shadow: 0px 6px 20px 0px rgba($gray-6, .1);

// Z-indexes
// Use appropriate names here. Name either based on component use if needed
// or make generic indexes based on repeated patterns.
$global-z-indexes: (
  navigation : 100,
  flyout : 90,
);