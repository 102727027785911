// Class is used when results are display in a tabular fashion
.result-table {
    table-layout: auto;
    border: none;
    border-collapse:separate; 
    border-spacing: 0 $global-spacing-unit-tiny;
    
    &__total {
        display: block;
        width: 100%;
        border-bottom: 1px solid $gray-3;
        //margin-bottom: $global-spacing-unit-tiny;
        padding-bottom: $global-spacing-unit-tiny;
    }
    
    &__header {
        text-transform: uppercase;
        @include font-size($font-size-small);
        color: $gray-6;
        
        > th {
            border: none;
            padding: 0 $global-spacing-unit-small;
        }
    }
    
    &__item {
        background: $white;
        border: 1px solid $gray-6;
        margin-bottom: $global-spacing-unit-small;
        vertical-align: top;
        @include font-size($font-size-medium);
        
         ul {
            li {
                margin-bottom: $global-spacing-unit-tiny;
            }
        }
        
        > td {
            border: none;
            padding: $global-spacing-unit-small $global-spacing-unit-small;
        }
    }
    
    &__item-title {
        @include font-size($font-size-medium); 
        font-weight: 600;
        color: $font-color;
    }
    
    &__item-actions {
        @include font-size($font-size-large);
        text-align: right;
        min-width: 150px;
        
        > a,
        >span {
            display: inline-block;
            margin-right: $global-spacing-unit-small;
            
            &:last-child {
                margin-right: 0;
            }
        }
    }
    
    table {
        table-layout: auto;
        margin-bottom: 0;
        
        td, th {
            padding: 0;
        }
    }
    
    &--zebra {
        .result-table__item {
            border: none;
            
            &:nth-child(even) {
                background: $gray-2;
            }
            
            &:nth-child(odd) {
                background: $white;
            }
        }
    }
}

// Class is used for generic results
.result {
    margin-bottom: $global-spacing-unit-small;
    padding: $global-spacing-unit-small;
    background: $white;
    box-shadow: $global-box-shadow;
    
    &__subheader {
        display: block;
        color: $gray-6;
        @include font-size($font-size-medium);
    }
    
    &--line {
        border: 1px solid $gray-4;
        background: transparent;
        box-shadow: none;
    }
}