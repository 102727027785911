// VARS 
// Use these vars to style this component. 
// Before editing familiarize with the global settings.
// Be aware that these changes could cause inconsistencies throughtout the design.

$btn-padding-unit-x: $global-spacing-unit-tiny * 1.5;
$btn-padding-unit-y: $global-spacing-unit-tiny * 1.5;
$btn-radius: $global-border-radius;
$btn-transition: $global-transition-time;
$btn-font-weight: $font-weight-bold;

$btn-colors: (
    action:     $theme-primary-1,
    neutral:    $theme-primary-2,
    success:    #1dd1a1,
    failure:    #ff7675,
);

/*
* Generic filled button
* Variants/Modifiers: Filled(Primary, Secondary), Muted(Default/Gray, Primary), Ghost(Default/Gray, Primary)
*                     Shadows (Default/None, Inner Shadow, Large Box Shadow)
*                     Sizes (Default, Large, Small)
*/
.btn {
    display: inline-block;
    vertical-align: middle;
    padding: $btn-padding-unit-y $btn-padding-unit-x;
    border-radius: $btn-radius;
    @include font-size($font-size-small, 1);
    text-decoration: none;
    transition: $btn-transition;
    text-transform: uppercase;
    font-weight: $btn-font-weight;
    letter-spacing: 1px;
    text-align: center;
    appearance: none;
    border: 1px solid;
    cursor: pointer;
    
    &[disabled="disabled"],
    &:disabled {
        cursor: not-allowed;
        opacity: .85;
    }
    
    // Button Color Variants
    @each $name, $color in $btn-colors {
        &--#{$name} {
            background-color: $color;
            color: choose-contrast-color($color);
            border-color: $color;

            &:hover,
            &:focus {
                
                @if $name == action {
                    background-color: $theme-primary-2;
                    border-color: $theme-primary-2;
                    color: choose-contrast-color($theme-primary-2);
                } @else {
                    background-color: darken($color, 5%);
                    border-color: darken($color, 5%);
                    color: choose-contrast-color($color);
                }
            }
            
            &[disabled="disabled"],
            &:disabled {
                background-color: desaturate( $color, 30% );
                border-color: desaturate( $color, 30% );
                
                &:hover,
                &:focus {
                    background-color: desaturate( $color, 30% );
                    border-color: desaturate( $color, 30% );
                }
            }
        }
    }
    
    // Ghost Button Variants
    @each $name, $color in $btn-colors {
        &--ghost-#{$name} {
            border: 1px solid $color;
            background: transparent;
            color: $color;

            &:hover,
            &:focus {
                background-color: $color;
                color: $white;
                border-color: $color;
            }
            
            &[disabled="disabled"],
            &:disabled {
                background-color: transparent;
                border-color: desaturate( $color, 30% );
                
                &:hover,
                &:focus {
                    color: currentColor;
                    background-color: transparent;
                    border-color: desaturate( $color, 30% );
                }
            }
        }
    }
    
    // Button sizes
    &--small {
        font-size: $font-size-small;
        padding: ($btn-padding-unit-y * .5) ($btn-padding-unit-x * .5);
    }
        
    &--large {
        padding-left: $btn-padding-unit-x * 2;
        padding-right: $btn-padding-unit-x * 2;
        
        @include responsive(md) {
            @include font-size($font-size-medium, 1);
        }
    }
    
    &--block {
        display: block;
        width: 100%;
    }
    
    &--icon {
        @include font-size($font-size-medium, 1);
    }
    
    &--circle {
        border-radius: 100%;
    }
}

/*
* Styled Text link (Button without any frills).
* Variants/Modifiers: Arrows style modifier
*                     Sizes (Default, Large, Small)
*/
.btn-link {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    @include font-size($font-size-small, 1);
    text-transform: uppercase;
    font-weight: $btn-font-weight;
    text-decoration: none;
    background: none;
    border: none;
    margin-top: 1rem;
    margin-right: 2rem;
    appearance: none;
    color: $black;
    
    + .btn-link {
        margin-right: 2rem;
    }
        
    &--large {
        @include font-size($font-size-medium, 1);
    }
    
    &:hover {
        color: darken($theme-primary-1, 5%);
    }
}

.btn-group {
    display: flex;
    flex-direction: column;
    margin-left: -$global-spacing-unit-tiny;
    margin-right: -$global-spacing-unit-tiny;
    
    .btn,
    .btn-link {
        flex-basis: auto;
        margin: $global-spacing-unit-tiny;
    }
    
    @include responsive(md) {
        flex-direction: row;
    }
}