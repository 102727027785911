$alert-colors: (
    neutral: $gray-3,
    caution: $util-orange-crush,
    success: $util-green,
    failure: $util-red,
);

.alert {
    display: block;
    padding: $global-spacing-unit-small;
    @include font-size($font-size-medium);
    font-weight: $font-weight-semibold;
    margin-bottom: $global-spacing-unit-small;
    
    @each $name, $color in $alert-colors {
        &--#{$name} {
            background-color: lighten($color, 25%);
            color: $color;
        }
    }
}