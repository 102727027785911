/**
* Returns content wrapped media query
*
* This resonsive query accepts both values from the SCSS map for queries as well as custom values
*
*/
@mixin responsive($bp, $breakpoints: $site-breakpoints) {
    
  //Use values from settings.media
  @if map-has-key($breakpoints, $bp) {
    @media (min-width: #{map-get($breakpoints, $bp)}) {
      @content;
    }
  }

  //Use Custom values for min and max width querires
  @else {
    @if (str-replace($bp, 'max-')) {
      $max-bp: str-replace($bp, 'max-', '');

      @if map-has-key($breakpoints, $max-bp) {
        @media (max-width: #{(map-get($breakpoints, $max-bp)-1)}) {
          @content;
        }
      }
    }

    @elseif (str-replace($bp, 'min-')) {
      $min-bp: str-replace($bp, 'min-', '');

      @if map-has-key($breakpoints, $min-bp) {
        @media (min-width: #{map-get($breakpoints, $min-bp)}) {
          @content;
        }
      }
    }

    //Show error if value is non of the above params
    @else {
      @warn 'Unfortunately, no value could be retrieved from `#{$bp}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
  }
}