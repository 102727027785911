.header {
    position: fixed; 
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 22%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: $global-spacing-unit $global-spacing-unit $global-spacing-unit-small ;
    background-color: $gray-6;
    
    &__sub {
        margin-top: auto;
        @include font-size($font-size-medium);
        color: $gray-4;
    }
    
    &__logo { 
        display: block;
        width: 100%;
        max-width: 15rem;
        margin: 0 0 $global-spacing-unit-small;
    }
    
    &__logo-letter {
        fill: $white;
    }
    
    &__controls {
        background: $white;
        @include font-size($font-size-medium);
        font-weight: $font-weight-bold; 
        margin-bottom: $global-spacing-unit;
        
        .icon-on-left {
            @include font-size($font-size-large, 1);
            transform: translateY(2px); // Ridiculous but needed to center
        }
    }
    
    &__controls-grid {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    &__controls-list {
        > li {
            border-left: 1px solid $gray-3;
            padding: $global-spacing-unit-small;
            
            &:first-child {
                border: none;
            }
        }
    }
    
    &.header-login .header__logo {
        max-width: 20rem;
        margin: 0 auto $global-spacing-unit-small;
    }
        
    &.header-login .header__logo-letter {
        fill: $black;
    }
}