/* 
*   These spacing units are used on a global scale for all components in some capacity.
*   Do not reassign these variables to another value outside of this config.
*/

$spacing-baseline: 10px !default;

$spacing-unit-factor-tiny:   1 !default;   // 10px
$spacing-unit-factor-small:  2 !default;   // 20px
$spacing-unit-factor:        4 !default;   // 40px
$spacing-unit-factor-large:  8 !default;   // 80px
$spacing-unit-factor-huge:   10 !default;  // 100px

$global-spacing-unit-tiny:  $spacing-baseline * $spacing-unit-factor-tiny !default;
$global-spacing-unit-small: $spacing-baseline * $spacing-unit-factor-small !default;
$global-spacing-unit:       $spacing-baseline * $spacing-unit-factor !default;
$global-spacing-unit-large: $spacing-baseline * $spacing-unit-factor-large !default;
$global-spacing-unit-huge:  $spacing-baseline * $spacing-unit-factor-huge !default;