/* ==========================================================================
#CROP
========================================================================== */
/**
* A list of cropping ratios that get generated as modifier classes.
* You should predefine it with only the ratios and names your project needs.
*
* The map keys are the strings used in the generated class names, and they can
* follow any convention, as long as they are properly escaped strings. i.e.:
*
*   $examples: (
*     "2\\:1"         : (2:1),
*     "4-by-3"        : (4:3),
*     "full-hd"       : (16:9),
*     "card-image"    : (2:3),
*     "golden-ratio"  : (1.618:1) -> non-integers are okay
*   ) !default;
*/

$theme-crops: (
    "1by1"   : (1:1),  // 100%, Square
    "4by3"   : (4:3),  // 75%, Typical image size ( simplified 6by4 )
    "16by9"  : (16:9),  // 56.25%, Typical video
    "6by5"   : (6:5),  // 83.33%,
    "5by2"   : (5:2)  // 83.33%,
) !default;


//Provide a cropping container in order to display media (usually images) cropped to certain ratios.
//cropped to certain ratios.

.crop {
    position: relative; /* [1] */
    display: block;
    overflow: hidden; /* [2] */
}

// Apply this class to the content (usually `img`) that needs cropping. Similar to an embed responsive class
.crop__content {
    position: absolute;
    top:  0; /* [1] */
    left: 0; /* [1] */
    max-width: none; /* [2] */
}

//Position modifiers that will position the cropped content.
.crop__content--left-top {
    left: 0;
}
.crop__content--left-center {
    top: 50%;
    transform: translateY(-50%);
}
.crop__content--left-bottom {
    top: auto;
    bottom: 0;
}
.crop__content--right-top {
    right: 0;
    left: auto;
}
.crop__content--right-center {
    top: 50%;
    right: 0;
    left: auto;
    transform: translateY(-50%);
}
.crop__content--right-bottom {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
}
.crop__content--center-top {
    left: 50%;
    transform: translateX(-50%);
}
.crop__content--center,
.crop__content--center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.crop__content--center-bottom {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}


/**
* Generate a series of crop classes to be used like so by looping through the ratios defined above:
*
*  <div class="crop crop--golden-ratio">
*
*/
@each $crop-name, $crop-value in $theme-crops {
    @each $numerator, $denominator in $crop-value {
        @if (type-of($numerator) != number) {
          @error "`#{$numerator}` needs to be a number.";
        }

        @if (type-of($denominator) != number) {
          @error "`#{$denominator}` needs to be a number.";
        }

        .crop--#{$crop-name} {
            &:before {
                content: "";
                display: block;
                padding-bottom: ($denominator/$numerator) * 100%;
            }
        }
    }
}


/**
* Content stretches to fill it's container while maintaining aspect-ratio.
*/
.crop--fill {
    > .crop__content {
        min-height: 100%;
        min-width: 100%;
    }
}