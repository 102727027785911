.card {
    display: flex;
    flex-direction: column;
    background: $white;
    padding: $global-spacing-unit;
    box-shadow: $global-box-shadow;
    
    &__icon {
        @include font-size(20px, 1);
        color: $theme-primary-1;
    }
    
    &--compact {
        padding: $global-spacing-unit-small;
    }
}

.card-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -($global-spacing-unit-tiny);
    
    &__item {
        margin: $global-spacing-unit-tiny;
        flex: 1 1 auto;
    }
    
    &--halves {
        .card-row__item {
            margin: $global-spacing-unit-tiny;
            flex: 0 0 calc(50% - #{$global-spacing-unit-small});
            max-width: calc(50% - #{$global-spacing-unit-small});
        }
    }
    
    &--quarters {
        .card-row__item {
            margin: $global-spacing-unit-tiny;
            flex: 0 0 calc(25% - #{$global-spacing-unit-small});
            max-width: calc(25% - #{$global-spacing-unit-small});
        }
    }
}