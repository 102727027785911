.list-inline {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    text-indent: inherit;
    
    > li {
        margin: 0 $global-spacing-unit-tiny;
        
        &:first-child {
            margin-left: 0;
        }
        
        &:last-child {
            margin-right: 0;
        }
        
        &:before {
            display: none;
        }
    }
    
    &--spaced {
        > li {
            margin: 0 $global-spacing-unit;
        }
    }
}

.list-inline-respond {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-indent: inherit;
    
    > li {
        margin: .5rem 0;
        width: 100%;
        
        &:before {
            display: none;
        }
    }
    
    @include responsive(md) {
        flex-direction: row; 

        > li {
            margin: 0 $global-spacing-unit-tiny;
            width: auto;
            
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.list-blank,
%list-blank {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: inherit;
    
    li {
        &:before {
            display: none;
        }
    }
}

.list-cols {
    text-indent: inherit;
    
    li {
        margin: $global-spacing-unit-tiny 0;
        
        &:before {
            display: none;
        }
    }
    
    @include responsive(md) {
        column-count: 2;
    }
}
    
.list-scroll-mob {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    margin-bottom: $global-spacing-unit-small;
    margin-right:  -($global-wrapper-spacing-sm * 2);
    list-style: none;
    text-indent: inherit;
    
    &__item {
        white-space: nowrap;
        min-width: inherit;
        margin: $global-spacing-unit-tiny;
        
        &:before {
            display: none;
        }
    }
    
    @include responsive(md) {
        flex-wrap: wrap;
        margin-right: -$global-spacing-unit-tiny;
        margin-left:  -$global-spacing-unit-tiny;
        
        &__item {
            white-space: normal;
            flex: 1 1 auto;
        }
    }
}