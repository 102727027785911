.ui-timepicker-list {
    @extend %list-blank;
    background:$white;
    box-shadow: 0 0 10px 0 rgba($gray-6, .1);
    max-height: 300px;
    overflow-y: auto;
    min-width: 180px;
    border-radius: $global-border-radius;
    padding-top: $global-spacing-unit-small;
    padding-bottom: $global-spacing-unit-small;
    
    &::-webkit-scrollbar {
        width: .8rem;
    }

    &::-webkit-scrollbar-track {
        background-color: $gray-3;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray-5;
        outline: 1px solid $gray-5;
        border-radius: .4rem;
    }
    
    > li {
        padding: $global-spacing-unit-tiny $global-spacing-unit-small;
    }
    
    .ui-timepicker-selected {
        background: $theme-primary-1;
        color: $white;
    }
}