/*
*
* Modal styling that was already in place
*
*/
.modalBackground {
    background: rgba($gray-5, .7);
}

.modalPopup {
    background-color:$white;  
    height: 85vh;
    max-width: 1000px;
    width: 100%;
    overflow-y: auto;
    border: none !important;
    padding: $global-spacing-unit !important;
    border-radius: 0 !important;
    font-family: $font-sans-serif !important;
}


.modal-fake {
    position: fixed;
    background: rgba($gray-6, .5);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &__close {
        float: right;
        max-width: 3rem;
        opacity: .3;
        transition: $global-transition-time;
        
        &:hover {
            opacity: 1;
        }
    }
    
    &__window {
        height: 85vh;
        width: 90%;
        max-width: 1000px;
        overflow-y: scroll;
        background-color:$white;  
        padding: $global-spacing-unit;
        
        > table {
            display: block;
            border: none;
        }
    }
}