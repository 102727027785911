/**
 * Function for dynamic padding classes
 *
 * You will want to set up your elements like so:
 * <div class="pa__1">, <div class="pt__2 pb__2">, etc.
 *
 * The below mixin dynamically creates CSS based off of the $spacing-baseline set
 * in the _settings.spacing.scss file (multiplies it by the $i variable)
 */
$classes-count: 10;
@mixin spacing-classes($prefixes) {
    @for $i from 0 through $classes-count {
        @each $attr-short, $attr-long in $prefixes {
            .#{$attr-short}__#{$i} {
                #{$attr-long}: $spacing-baseline * $i;
            }
        }
    }
}
