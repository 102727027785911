/**
* Colours
*/

//Monochrome
$black: #000000 !default;
$white: #FFFFFF !default;


// ORIGINAL COLOR SCHEME - BLUE
//
// Theme Grays
// Grays increase in darkness with increasing number
//$gray-1: #FAFAFA !default;
//$gray-2: #f5f8fb !default;
//$gray-3: #dfe4ea !default;
//$gray-4: #a8b3c1 !default;
//$gray-5: #4b6584 !default;
//$gray-6: $black !default;
//$gray-7: #203c58 !default;
//$gray-8: #231F20 !default;

// Primary Colors
// Primary colors increase in darkness with increasing number
//$theme-primary-1: #3742fa !default;
//$theme-primary-2: #64D761 !default;


// MEGRE COLOR SCHEME
//
// Theme Grays
// Grays increase in darkness with increasing number
$gray-1: #FAFAFA !default;
$gray-2: #f5f5f5 !default;
$gray-3: #d4d4d4 !default;
$gray-4: #adadad !default;
$gray-5: #525A5A !default;
$gray-6: #000000 !default;
$gray-7: #525A5A !default;
$gray-8: #212721 !default;

// Primary Colors
// Primary colors increase in darkness with increasing number
$theme-primary-1: #1ed75f !default;
$theme-primary-2: #00524F !default;

// Utility Colors
$util-red:              #ff7675 !default;
$util-yellow:           #fff7cf !default;
$util-green:            #1dd1a1 !default;
$util-orange-crush:     #FC955E !default;

//Global Colors
$heading-color: $gray-8;
$font-color: $gray-8;

$link-color:        $theme-primary-1;
$link-color-hover:  $theme-primary-2;