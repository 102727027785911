@mixin font-size($font-size, $line-height: auto, $modifier: 0, $important: false) {

  @if (type-of($font-size) == number) {
    @if (unit($font-size) != "px") {
      @error "`#{$font-size}` needs to be a pixel value.";
    }
  } @else {
    @error "`#{$font-size}` needs to be a number.";
  }

  @if ($important == true) {
    $important: !important;
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error "`#{$important}` needs to be `true` or `false`.";
  }

  // We provide a `px` fallback for old IEs not supporting `rem` values.
  font-size: $font-size $important;
  font-size: ($font-size / $spacing-baseline) * 1rem $important;

  @if ($line-height == "auto") {

    // Define how many grid lines each text line should span.
    // By default, we set it to the minimum number of lines necessary
    // in order to contain the defined font-size, +.25 for some breathing room.
    // This can be modified with the `$modifier` parameter.
    $lines: ceil($font-size / $spacing-baseline) + $modifier;
    $line-height: $lines * $spacing-baseline;
    $breathing-room: null;
      
    @if ($font-size > 20) {
        $breathing-room: .15;
    } @else {
        $breathing-room: .25;
    }

    line-height: (($line-height / $font-size) + $breathing-room) $important;
  }

  @else {

    @if (type-of($line-height) == number or $line-height == "inherit" or $line-height == "normal") {
      line-height: $line-height $important;
    }

    @else if ($line-height != 'none' and $line-height != false) {
      @error "D’oh! `#{$line-height}` is not a valid value for `$line-height`."
    }

  }

}
