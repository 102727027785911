.grid {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: -($grid-spacer);
    padding: 0;
    
    &__item {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        padding: $grid-spacer;
    }
    
    &__width {
        @for $i from 1 through $grid-column-count {
            &--#{$i} {
                @include make-grid-item($i);
            }
        }
    }
    
    /*
    *
    *   Loop through each breakpoint specified in settings.layout
    *   and create colunms and grid wrapper for each breakpoint
    *
    */
    @each $bp in $grid-breakpoints {
        @include responsive($bp) {
            &--#{$bp} {
                flex-direction: row;
                flex-wrap: wrap;
            }

            &__width-#{$bp} {
                @for $i from 1 through $grid-column-count {
                    &--#{$i} {
                        @include make-grid-item($i);
                    }
                }
            }
            
            &__offset-#{$bp} {
                @for $i from 1 through $grid-column-count {
                    &--#{$i} {
                        padding-left: percentage($i / $grid-column-count)
                    }
                }
            }
        }
    }
}


/*
*
*   These modifiers can be used as either classes on a grid block
*   or they can be used on other flex wrappers by extending the placeholder.
*
*/
@if $grid-enable-mods {
    .grid--top,
    %grid--top {
        align-items: flex-start;
    }

    .grid--center,
    %grid--center {
        align-items: center;
    }

    .grid--bottom,
    %grid--bottom {
        align-items: flex-end;
    }

    .grid--split,
    %grid--split {
        justify-content: space-between;
    }

    .grid--full,
    %grid--full {
        margin: 0;
    }
}