.sidenav { 
    @include font-size($font-size-medium);
    font-weight: $font-weight-bold;
    
    &__item {
        
        margin-bottom: 0;
        
        > a {
            display: inline-block;
            color: $gray-8;
            transition: color $global-transition-time;
            
            &:hover {
                color: $theme-primary-1;
            }
        }
        
        
        &.is-active {
            > a {
                color: $gray-8;
            }
        }
    }
    
    &__children {
        @include font-size($font-size-small);
        font-weight: $font-weight-regular;
    }
}