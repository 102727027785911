//Use this class to create a table the is a single column.
.block-table {
    display: block;
    margin-bottom: 0;
    
    > tbody {
        display: block;
        
        > tr {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 0 $global-spacing-unit-small;
            
            > td {
                padding: 0;
                display: block;
                width: 100%;
            }
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.table-auto {
    table-layout: auto;
}