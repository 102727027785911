.nav {
    position: relative;
    padding: 0;
    display: none;
    justify-content: space-between;
    position:fixed;
    @include z-index(navigation);
    width:100%;
    left:0;
    background-color:$gray-1;
    @include responsive(md){
        display:flex;
        position:relative;
        background-color:transparent;
    }
    
    > ul {
        width: 100%;
    }
    
    &__item {
        display: block;
        width: 100%;
        cursor: pointer;
        margin: ($global-spacing-unit-small + $spacing-baseline) 0;
        font-weight: $font-weight-bold;
        border-bottom: 1px solid rgba($white, .05);
        padding-bottom: ($global-spacing-unit-small + $spacing-baseline);
            
        > a {
            display: block;
            position: relative;
            color: rgba($white, .75);
            
            &:hover {
                color: $white;
            }
        }
        
        .icon-on-left {
            @include font-size($font-size-large, 1);
        }
        
        &.is-current {
            a {
                color: $white;
            }
        }
        
        &--sub {
            @include font-size($font-size-medium);
            font-weight: 400;
            margin: $global-spacing-unit-tiny 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    
    &__arrow {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -7px;
        @include font-size($font-size-medium, 1);
    }
    
    &__dropdown {
        padding-left: $global-spacing-unit-small * 1.5;
    }
}

.nav-list {
    display: block;
    
    &__item {
        margin-bottom: $global-spacing-unit-tiny;
        font-weight: $font-weight-semibold;
        
        &:last-child {
           margin-bottom: none; 
        }
    }
}