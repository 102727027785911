h1,
%h1 {
    @include font-size($font-size-h1-mobile);
    color: $heading-color;
    font-weight: $font-weight-bold;
    
    @include responsive(lg) {
        @include font-size($font-size-h1);
    }
}

h2,
%h2 {
    @include font-size($font-size-h2-mobile);
    color: $heading-color;
    font-weight: $font-weight-bold;
    
    @include responsive(lg) {
        @include font-size($font-size-h2);
    }
}

h3,
%h3 {
    @include font-size($font-size-h3-mobile);
    color: $heading-color;
    font-weight: $font-weight-bold;
    
    @include responsive(lg) {
        @include font-size($font-size-h3);
    }
}

h4,
%h4 {
    @include font-size($font-size-h4-mobile);
    color: $heading-color;
    font-weight: $font-weight-bold;
    
    @include responsive(lg) {
        @include font-size($font-size-h4);
    }
}

h5,
%h5 {
    @include font-size($font-size-h5-mobile);
    color: $heading-color;
    font-weight: $font-weight-bold;
    
    @include responsive(lg) {
        @include font-size($font-size-h5);
    }
}

h6,
%h6 {
    @include font-size($font-size-h6);
    color: $heading-color;
    font-weight: $font-weight-bold;
}

a {
    text-decoration: none;
    color: $link-color;
    text-decoration-skip: ink;
    font-weight: $font-weight-bold;
    transition: $global-transition-time;
    
    &:hover {
        color: $link-color-hover;
    }
}

address {
    font-style: normal;
}