.footer {
    padding: $global-spacing-unit-small 0;
    background-color:$gray-3;
    @include responsive(md){
    	padding: $global-spacing-unit-large 0;
    }
    .wrapper{
    	padding-left:0;
    	padding-right:0;
    	@include responsive(md){
    		padding-left:20px;
    		padding-right:20px;
    	}
    }
    .grid{
    	&__item{
    		background-color:$theme-primary-2;
    		border-bottom:1px solid $gray-2;
    		position:relative;
    		@include responsive(sm){
    			background-color:transparent;
    			border-bottom:none;
    		}
    		&.footer__logo{
		    	text-align:center;
		    	background-color:transparent;
		    	border-bottom:none;
		    	@include responsive(md){
		    		padding-right:100px;
		    	}
		    	img{
		    		max-width:278px;
		    		width:100%;
		    	}
		    }
    		> span{
	    		font-weight:$font-weight-bold;
	    		color:$white;
	    		display:block;
	    		padding:$global-spacing-unit-tiny;
	    		@include responsive(sm){
	    			color:$black;
	    			padding:0;
	    		}
	    		&:after{
	    			content:"+";
	    			color:$white;
	    			position:absolute;
	    			top:20px;
	    			right:20px;
	    			transform:rotate(0deg);
	    			transition: transform .75s;
	    			@include responsive(sm){
	    				display:none;
	    			}
	    		}
	    		&.is-active{
	    			&:after{
	    				transform:rotate(-315deg);
	    				transition: transform .75s;
	    			}
	    		}
	    	}
	    	ul{
	    		li{
	    			a{
			    		color:$white;
			    		padding:$global-spacing-unit-tiny $global-spacing-unit-tiny;
			    		display:block;
			    		@include responsive(sm){
			    			color:$black;
			    			padding:0;
			    		}
			    	}
	    		}
	    	}
    	}
    }
}