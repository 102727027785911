// VARS 
// Use these vars to style this component. 
// Before editing familiarize with the global settings.
// Be aware that these changes could cause inconsistencies throughtout the design.

$input-padding-unit-x: $global-spacing-unit-tiny;
$input-padding-unit-y: $global-spacing-unit-tiny;
$input-radius: $global-border-radius;
$input-transition: $global-transition-time;
$input-font-weight: $font-weight-bold;

$checkbox-ui-width: 50px;

.form {
    padding: $global-spacing-unit;
    background: $white;
    
    &__item {
        position: relative;
        margin-bottom: $global-spacing-unit-small;
    }
    
    &__subtitle {
        display: block;
        @include font-size($font-size-medium);
        margin-bottom: $global-spacing-unit-small;
    }
    
    &__label {
        display: block;
        margin-bottom: ($global-spacing-unit-tiny);
        
        &--sub {
            @include font-size($font-size-medium);
        }
        
        &--inline {
            display: inline-block;
            margin-left: $global-spacing-unit-tiny;
        }
    }
    
    &__description {
        display: block;
        @include font-size($font-size-medium);
    }
    
    &__synced-group {
        position: relative;
    }
    
    &__synced-field {
        position: absolute;
        right: $global-spacing-unit-small;
        bottom: 23px;
        
        &:before {
            content: fa-content($fa-var-sync-alt);
            font-family: 'Font Awesome 5 Pro';
            font-weight: 700;  
            color: $gray-7;
            @include font-size($font-size-medium);
        }
        
        &:after {
            content: "Synced Field";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: $white;
            display: inline-block;
            width: 85px;
            box-shadow: 0px 6px 20px 0px rgba($gray-6, .1);
            border-radius: $global-border-radius;
            opacity: 0;
            visibility: hidden;
            transition: $global-transition-time;
            padding: $global-spacing-unit-tiny;
            @include font-size($font-size-medium);
            margin-left: ($global-spacing-unit-tiny/2);
            z-index: 20;
        }
        
        &:hover:after {
            opacity: 1;
            visibility: visible;
        }
    }
    
    &__input,
    &__select,
    &__multiselect {
        padding: $input-padding-unit-y $input-padding-unit-x;
        appearance: none;
        border-radius: $input-radius;
        outline: none;
        box-shadow: none;
        border: 1px solid $gray-4;
        background: $white;
        transition: $global-transition-time;
        @include font-size($font-size-medium);
        
        &:focus {
            border-color: $theme-primary-1;
            background-color: $gray-1;
        }
        
        &[disabled="disabled"],
        &:disabled {
            cursor: not-allowed;
            background: $gray-3;
            color: $gray-5;
        }
        
        &--full {
            width: 100%;
        }
        
        &--half {
            display: block;
            width: 100%;
            max-width: 50%;
        }
        
        &--small {
            padding: ($input-padding-unit-y - 5) $input-padding-unit-x;
        }
        
        &--icon {
            padding-left: $input-padding-unit-x * 3.5;
        }
    }
    
    &__select {
        background-image: url(/admin/AImages/dropdown-arrow.svg);
        background-size: 11px;
        background-position: calc(100% - #{$input-padding-unit-x}) 50%;
        padding-right: 20px;
        background-repeat: no-repeat;
        padding-right: $input-padding-unit-x * 3;
    }
    
    &__multiselect {
        > option {
            padding: ($global-spacing-unit-tiny / 2) ($global-spacing-unit-tiny * 1.5);
            margin: ($global-spacing-unit-tiny / 2);
            @include font-size($font-size-medium);
            
            &:selected {
                background: $theme-primary-1;
            }
        }    
    }
    
    &__checkbox-slider {
        cursor: pointer;
        text-indent: -9999px;
        width: $checkbox-ui-width;
        height: ($checkbox-ui-width/2);
        background: $gray-4;
        display: block;
        border-radius: $checkbox-ui-width;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 3px;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 20px;
            transition: $global-transition-time;
        }
    }
    
    &__checkbox {
        margin-bottom: $global-spacing-unit;
        
        &--ui {
            position: relative;
            padding-right: $global-spacing-unit-large;
            
            input[type="checkbox"],
            .form__checkbox-slider {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            
            .form__checkbox-slider {
                z-index: 0;
            }
            
            input[type="checkbox"] {
                width: $checkbox-ui-width;
                height: ($checkbox-ui-width/2);
                opacity: 0;
                z-index: 1;
                cursor: pointer;
            }
            
            input[type="checkbox"]:checked + .form__checkbox-slider {
                background: $theme-primary-1;
                
                &:after {
                    left: calc(100% - 3px);
	                transform: translateX(-100%);
                }
            }
        }
    }
    
    &__radio {
        margin-bottom: 0;
        
        input[type="radio"] {
            position: absolute;
            left: 0;
            opacity: 0;
            visibility:hidden;
        }
        
        label {
            display: inline-block;
            position: relative;
            
            &:before {
                content: "";
                position: relative;
                display: inline-block;
                vertical-align: middle;
                height: 2rem;
                width: 2rem;
                border: 1px solid $gray-4;
                border-radius: 100%;
                margin-right: $global-spacing-unit-tiny;
            }
            
            &:after {
                content: "";
                position: absolute;
                display: none;
                height: 1.2rem;
                width: 1.2rem;
                border-radius: 100%;
                background: $theme-primary-1;
                left: 5px;
                top: 1.15rem;
                margin-top: -4px;
            }
        }
        
        input[type="radio"]:checked + label:after {
            display: block;
        }
    }
    
    &__icon {
        @include font-size($font-size-large);
        position: absolute;
        left: $input-padding-unit-x;
        top: 50%;
        transform: translateY(-50%)translateY(15px);
        color: $gray-6;
    }
    
    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: $global-spacing-unit;
        
        &--col {
            flex-direction: column;
            align-items: start;
        }
    }
    
    &--floating {
        max-width: 600px;
        margin: 0 auto;
        box-shadow: 0 0 20px 0 rgba($gray-6, .1);
        border-radius: $global-border-radius;
    }
    
    &--flyout {
        position: fixed;
        top: 0;
        right: -100%;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        max-width: 500px;
        z-index: 20;
        transition: $global-transition-time;
        transition-timing-function: cubic-bezier(.44,.22,.45,.99);
        
        &.is-active {
            right: 0;
        }
        
        &__backdrop {
            content: "";
            position: fixed;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($gray-5, .7);
        }
        
        &__close {
            @include font-size(34px, 1);
            text-align: right;
            color: $gray-4;
            cursor: pointer;
            transition: $global-transition-time;
            
            &:hover {
                color: $gray-6;
            }
        }
    }
}

.form-row {
    display: flex;
    margin: 0 (-($global-spacing-unit-small));
        
    &--center {
        align-items: center;
    }
        
    &--bottom {
        align-items: flex-end;  
    }
    
    &__item {
        flex: 1 1 auto;
        padding: 0 $global-spacing-unit-small;
        
        > input[type="text"],
        > select {
            display: block;
            width: 100%;
        }
        
        &--shrink {
            flex: 0 0 auto;    
        }
    }
}

.form-group {
    display: block;
    margin-bottom: $global-spacing-unit-small;
    
    > .form__label {
        display: inline-block;
        margin-right: $global-spacing-unit-small;
    }
    
    @include responsive(md) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}