.page-body {
    position: relative;
    left: 22%;
    width: 100%;
    max-width: 78%;
    background-color:$gray-2;
    padding-bottom: $global-spacing-unit;
}

.page-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $global-spacing-unit;
    
    &__actions {
        margin: 0 (-($global-spacing-unit-tiny));
        > .btn {
            margin: 0 $global-spacing-unit-tiny;
        }
    }
}

.wrapper { 
    padding-right: $global-wrapper-spacing-sm;
    padding-left:  $global-wrapper-spacing-sm;
    margin-right: auto;
    margin-left:  auto;
    max-width: $global-wrapper-width;

    &--offset {
        margin: 0;
    }
    
    @include responsive(md) {
        padding-right: $global-wrapper-spacing-lg;
        padding-left:  $global-wrapper-spacing-lg;
    }
}