.overlay-item {
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
    align-items: center;
    
    &--col {
        flex-direction: column;
    }
    
    &__image {
        position: relative;
        width: 60%;
        height: 100%;
        background: $black;
        
        > svg {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
    
    &__modal {
        width: 40%;
        padding: $global-spacing-unit-large;
    }
}

//@keyframes spin { 
//    100% { 
//        transform:rotate(360deg); 
//    }
//}
//
//.animate-cog {
//    animation:spin 4s linear infinite;
//}