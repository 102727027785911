html {
  font-size: $spacing-baseline; 
  line-height: 1.25; 
  overflow-y: scroll; 
  min-height: 100%; 
}

body {
    color: $font-color;
    @include font-size($font-size-base-mobile);
    font-family: $font-sans-serif;
    font-weight: $font-weight-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:$gray-2;
    
    @include responsive(md) {
        @include font-size($font-size-base);
    }
}

*::selection {
  color: $white;
  background: $theme-primary-1;
}

/**
 * Where `margin-bottom` is concerned, this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 */
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dl,
blockquote,
p,
address,
table,
fieldset,
figure,
pre,
hr {
  margin-bottom: $global-spacing-unit-small;
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount.
 */
ul,
ol,
dd {
    margin-left: $global-spacing-unit-small;
}

ul {
    list-style: none;
    margin-left: 0;
    padding-left: 2rem;
    text-indent: -1rem;
    
    > li {
        &:before {
            content: "•";
            display: inline-block;
            vertical-align: middle;
            padding-right: 8px;
            color: $theme-primary-1;
            font-size: 2.2rem;
        }
    }
}

pre {
    display: block;
    background-color: $gray-7;
    color: $white;
    padding: $global-spacing-unit;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
    overflow-x: auto;
}

code {
   display: inline-block;
    vertical-align: middle;
    padding: 0 $global-spacing-unit-tiny;
    background-color: $gray-7;
    color: $white;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
    overflow-x: auto; 
}