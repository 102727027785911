/*
*   Used to make any heading or other typographic element display as if it were an H tag.
*   These are extended directly from the H tags so all responsive settings for those tags will apply here.
*/
.display-h1 {
    @extend %h1;
}

.display-h2 {
    @extend %h2;
}

.display-h3 {
    @extend %h3;
}

.display-h4 {
    @extend %h4;
}

.display-h5 {
    @extend %h5;
}

.display-h6 {
    @extend %h6;
}

.display-oversized {
    @include font-size($font-size-large);
}

// Text Alignment
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-muted {
    color: $gray-4;
}

.text-primary {
    color: $theme-primary-1;
}

.text-remove {
    color: $theme-primary-2;
    
    &:hover {
        color: $util-red;
    }
}


// Screen Reader Text
.sr-text {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
}