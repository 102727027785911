/**
* Type
*/

@import url(https://fonts.googleapis.com/css?family=Muli:300,400,500,700,900&display=swap);

$font-sans-serif: 'Muli', sans-serif;

$font-size-base:            16px; 
$font-size-base-mobile:     15px; 

$font-size-small:   12px;
$font-size-medium:  14px;
$font-size-large:   18px;

$font-weight-regular:   400;
$font-weight-semibold:  700;
$font-weight-bold:      900;

$font-size-h1:          54px !default;
$font-size-h1-mobile:   28px !default;

$font-size-h2:          38px !default;
$font-size-h2-mobile:   24px !default;

$font-size-h3:          32px !default;
$font-size-h3-mobile:   22px !default;

$font-size-h4:          28px !default;
$font-size-h4-mobile:   20px !default;

$font-size-h5:          22px !default;
$font-size-h5-mobile:   18px !default;

$font-size-h6:          18px !default;