.section {
    padding: ($global-spacing-unit-large/1.5) 0;
    
    &--padded {
        padding: ($global-spacing-unit-large/1.5) 0;
    }
    
    @include responsive(md) {
        padding: $global-spacing-unit-large 0;
        
        &--padded {
            padding: ($global-spacing-unit-huge*2) 0;
        }
        
        &--offset {
            padding: ($global-spacing-unit-large * 2) 0;
        }
        
        &--overlap-btm {
            margin-bottom: -($global-spacing-unit-large * 2);
        }
    }
}