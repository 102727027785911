/**
* Box Sizing Border Box
*/

/**
* Does the job of making an element
* respond it's parent padding
*/

* {
  box-sizing: border-box;
}
