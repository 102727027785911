$grid-spacer: 30px !default; // Spacing unit used between grid item
$grid-column-count: 12 !default;
$grid-col-width: 100% / $grid-column-count !default;

$grid-breakpoints: (
    xs,
    sm,
    md,
    lg
) !default;

$grid-enable-mods:      false !default; // Enable grid utility modifiers. Mostly used for grid alignment
$grid-enable-bs:        false !default; // Enable Bootstrap grid