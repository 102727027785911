.header.header-login {
    background-color: $gray-2;
    max-width: 40%;
    justify-content: center;
    align-items: center;
    padding: $global-spacing-unit-large;
    
    + .page-body {
        left: 40%;
        max-width: 60%;
        padding-bottom: 0;
    }
    
    .color-invert {
        text-align: center;
        color: $font-color;
    }
    
    .header__sub {
        text-align: center;
        margin-top: $global-spacing-unit;
        color: $font-color;
    }
}

.content-login {
    max-width: 100%;
    height: 100vh;
    padding: 0;
    
    .overlay-item__image {
        width: 100%;
    }
}

.call-center-grid {
    display: flex;
    flex-wrap: wrap;
    
    .colorBtn {
        flex: 0 0 25%;
        max-width: calc(25% - 20px);
        margin: 10px;
    }
    
    .btnDiv {
        height: auto;
    }
}

.page-list {
    
    .btn-link {
        font-weight: 700;
    }
    
    a {
        color: $black;
        font-weight: 400;
    }
    
    table {
        margin-bottom: 0;
        
        table td {
            padding: 0;
        }
    }
}