.flyout-menu {
    position: relative;
    
    &__trigger {
        cursor: pointer;
        
        &.is-active {
            > .fa-chevron-down {
                transform: rotate(180deg);
            }
        }
    }
    
    &__list {
        position: absolute;
        left: 0;
        top: 100%;
        min-width: 265px;
        background: $white;
        opacity: 0;
        visibility: hidden;
        padding: $global-spacing-unit-small;
        box-shadow: 0px 6px 20px 0px rgba($gray-6, .1);
        border-bottom-left-radius: $global-border-radius;
        border-bottom-right-radius: $global-border-radius;
        transition: $global-transition-time - .2;
        @include z-index(flyout);
        
        &.is-active {
            opacity: 1;
            visibility: visible;
        }
        
        //Stands for right anchored
        &--actions {
            right: -3rem;
            left: auto;
            top: calc(100% + 20px);
            box-shadow: 0 0 10px 0 rgba($gray-6, .1);
            @include font-size($font-size-medium);
            min-width: 175px;
            text-align: left;
            border-radius: $global-border-radius;
            
            &:before {
                content: "";
                position: absolute;
                top: -10px;
                right: 3rem;
                margin-left: -5px;
                width: 0; 
                height: 0; 
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $white;
                filter: drop-shadow(0 -4px 5px rgba($gray-6, .1));
            }
        }
    }
    
    &__divider {
        display: block;
        height: 1px;
        background: $gray-3;
        margin: $global-spacing-unit-small (-($global-spacing-unit-small));
    }
    
    &__item {
        margin-bottom: $global-spacing-unit-tiny;
        
        > a {
            font-weight: $font-weight-semibold;
            color: $gray-8;
        }
        
        &--close {
            > a {
                color: $util-red;
            }
        }
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}