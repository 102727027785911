.sidebar {
    &__item {
        margin: $global-spacing-unit 0;
        
        &:first-child {
            margin-top: 0;
        }
    }
    
    @include responsive(md) {
        padding-right: $global-spacing-unit-small;
    }
}